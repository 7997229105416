export const URL_HASH_FOR_COOKIE_MANAGEMENT = '#cookie-management'
export const URL_HASH_FOR_GAME = '#the-game'
export const URL_HASH_FOR_SHOP = '#the-shop'

export const ALLOWED_URL_HASHES = [URL_HASH_FOR_GAME, URL_HASH_FOR_SHOP]

export const ALL_URL_HASHES = [
  URL_HASH_FOR_COOKIE_MANAGEMENT,
  URL_HASH_FOR_GAME,
  URL_HASH_FOR_SHOP,
]

export const ACCEPTED_FILE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
]

export const PRIVACY_POLICY_URL =
  'https://cdn-partners.purchease.com/lustucru_assets/LUSTUCRU_CHARTE_DE_CONFIDENTIALITES_VDEF.pdf'

export const GAME_REGULATIONS_URL =
  'https://cdn-partners.purchease.com/lustucru_assets/LUSTUCRU_2024_REGLEMENT_DU_JEU_TF_PROMO_VDEF.pdf'

export const GAME_2_REGULATIONS_URL =
  'https://cdn-partners.purchease.com/lustucru_assets/LUSTUCRU_2024_REGLEMENT_DU_JEU_TF2_V6_230724.pdf'

export const GAME_2_CONSOLATION_PRIZE_URL =
  'https://cdn-partners.purchease.com/lustucru_assets/tf2/Lustucru_Livret_activit%C3%A9s_Minions_DEF.pdf'

export const UPLOAD_MAX_SIZE = 8 * 1024 * 1024
