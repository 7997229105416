import getEnv from '@/utils/runtime/env'

const TIMEOUT = 1000 // 1 second
const GTAG_UA_AD_CAMPAIGN = 'AW-16526954525/KVO5CIfmzMcZEJ2g1cg9'

// inspired by https://stackoverflow.com/a/33140274
export function createFunctionWithTimeout(callback: Function, timeout: number) {
  let called = false
  const toBeCalled = function () {
    if (!called) {
      called = true
      callback()
    }
  }
  setTimeout(toBeCalled, timeout)
  return toBeCalled
}

export function executeOnGtagCallback(callback: Function) {
  if (typeof window === 'undefined') return

  // @ts-ignore
  if (typeof window.gtag !== 'function') {
    return callback()
  }

  const env = getEnv()
  if (env !== 'production') return

  const sendTo = GTAG_UA_AD_CAMPAIGN

  const callbackWithExecutionOnTimeout = createFunctionWithTimeout(
    callback,
    TIMEOUT,
  )
  // @ts-ignore
  window.gtag('event', 'conversion', {
    send_to: sendTo,
    event_callback: callbackWithExecutionOnTimeout,
  })
}
